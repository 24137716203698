import { CSAT_RATINGS } from '../../../../../shared/constants/messages';

const generateInputSelectContent = contentAttributes => {
  const { submitted_values: submittedValues = [] } = contentAttributes;
  const [selectedOption] = submittedValues;

  if (selectedOption && selectedOption.title) {
    return `<strong>${selectedOption.title}</strong>`;
  }
  return '';
};

const generateInputEmailContent = contentAttributes => {
  const { submitted_email: submittedEmail = '' } = contentAttributes;
  if (submittedEmail) {
    return `<strong>${submittedEmail}</strong>`;
  }
  return '';
};

const generateFormContent = (contentAttributes, { noResponseText }) => {
  const { items, submitted_values: submittedValues = [] } = contentAttributes;
  if (submittedValues.length) {
    const submittedObject = submittedValues.reduce((acc, keyValuePair) => {
      acc[keyValuePair.name] = keyValuePair.value;
      return acc;
    }, {});
    let formMessageContent = '';
    items.forEach(item => {
      formMessageContent += `<div>${item.label}</div>`;
      const response = submittedObject[item.name] || noResponseText;
      formMessageContent += `<strong>${response}</strong><br/><br/>`;
    });
    return formMessageContent;
  }
  return '';
};

const generateCSATContent = (
  contentAttributes,
  { ratingTitle, feedbackTitle }
) => {
  const {
    submitted_values: { csat_survey_response: surveyResponse = {} } = {},
  } = contentAttributes;
  const { rating, feedback_message } = surveyResponse || {};

  let messageContent = '';
  if (rating) {
    const [ratingObject = {}] = CSAT_RATINGS.filter(
      csatRating => csatRating.value === rating
    );
    messageContent += `<div><strong>${ratingTitle}</strong></div>`;
    messageContent += `<p>${ratingObject.emoji}</p>`;
  }
  if (feedback_message) {
    messageContent += `<div><strong>${feedbackTitle}</strong></div>`;
    messageContent += `<p>${feedback_message}</p>`;
  }
  return messageContent;
};

const generateCardsContent = contentAttributes => {
  const submittedValues =
    contentAttributes.submitted_values instanceof Array
      ? contentAttributes.submitted_values.map(subValObj => subValObj.value)
      : [];
  const messageContentCSS =
    contentAttributes.items.length > 1
      ? `display: grid; grid-template-columns: repeat(${contentAttributes.items.length}, 150px); grid-gap: 10px;`
      : '';
  const actionsFlexDirection =
    contentAttributes.items.length > 1 ? 'column' : 'row';

  let messageContent = `<div style="${messageContentCSS}">`;
  contentAttributes.items.forEach(item => {
    let itemContent =
      '<div style="display: flex; flex-direction: column; justify-content: space-between;">';

    if (item.title) {
      itemContent += `<strong>${item.title}</strong>`;
    }

    if (item.description) {
      itemContent += `<p>${item.description}</p>`;
    }

    let itemActions = `<div style="display: flex; flex-direction: ${actionsFlexDirection}; justify-content: center; gap: 10px;">`;
    item.actions.forEach(action => {
      let isDisabled = true;
      if (submittedValues.includes(action.payload)) {
        isDisabled = false;
      }
      itemActions += `<button class="action-button button" style="gap: 10px;" ${
        isDisabled ? 'disabled' : ''
      }>${action.text}</button>`;
    });
    itemActions += '</div>';

    itemContent += itemActions;
    itemContent += '</div>';
    messageContent += itemContent;
  });

  messageContent += '</div>';
  return messageContent;
};

export const generateBotMessageContent = (
  contentType,
  contentAttributes,
  {
    noResponseText = 'No response',
    csat: { ratingTitle = 'Rating', feedbackTitle = 'Feedback' } = {},
  } = {}
) => {
  const contentTypeMethods = {
    input_select: generateInputSelectContent,
    input_email: generateInputEmailContent,
    form: generateFormContent,
    input_csat: generateCSATContent,
    cards: generateCardsContent,
  };

  const contentTypeMethod = contentTypeMethods[contentType];
  if (contentTypeMethod && typeof contentTypeMethod === 'function') {
    return contentTypeMethod(contentAttributes, {
      noResponseText,
      ratingTitle,
      feedbackTitle,
    });
  }
  return '';
};
